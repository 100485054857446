import React, { useState, useEffect } from "react";
import {
  Container,
  Typography,
  Button,
  Divider,
  Card,
  Grid,
  Stack,
  IconButton,
  CircularProgress,
  Dialog,
  DialogTitle,
  styled,
  Alert,
  DialogContent,
  DialogActions,
} from "@mui/material";
import CustomInput from "src/components/CustomInput";
import EditRoundedIcon from "@mui/icons-material/EditRounded";
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import { DropzoneArea, DropzoneDialog } from "material-ui-dropzone";
import { Face } from "@mui/icons-material";
import {
  uploadFileJp,
  createPersonalDocument,
  getPersonalDocument,
  updatePersonalDocument,
  setSnackbarOpen,
  uploadKtpJp,
  downloadFLK,
  candidateCheckApply,
} from "src/redux/actions";
import { useNavigate } from "react-router";
import DownloadDoneRoundedIcon from "@mui/icons-material/DownloadDoneRounded";
import CloseIcon from "@mui/icons-material/Close";
import LoadableImage from "src/components/LoadableImage";
import { KeyboardArrowLeft } from "@mui/icons-material";
import { loadImage } from "src/functions/utils";
import HistoryEduRoundedIcon from "@mui/icons-material/HistoryEduRounded";
import localforage from "localforage";
import ModalConfirmApplyJob from "src/components/Modal/ModalConfirmApplyJob";
import { version } from "src/configs/globalVariable";

function downloadBase64AsFile(base64Data, fileName) {
  const byteCharacters = atob(base64Data);
  const byteNumbers = new Array(byteCharacters.length);
  for (let i = 0; i < byteCharacters.length; i++) {
    byteNumbers[i] = byteCharacters.charCodeAt(i);
  }
  const byteArray = new Uint8Array(byteNumbers);
  const blob = new Blob([byteArray], { type: "application/octet-stream" });

  // Create a data URI
  const dataUri = URL.createObjectURL(blob);

  // Create a download link
  const downloadLink = document.createElement("a");
  downloadLink.href = dataUri;
  downloadLink.download = fileName;

  // Trigger the download
  downloadLink.click();

  // Clean up the data URI
  URL.revokeObjectURL(dataUri);
}

const CustomDialog = styled(Dialog)(() => ({
  "& .MuiDialog-paper": {
    backgroundColor: "rgb(255,255,255)",
    borderRadius: "1rem",
    border: "1px solid lightgrey",
  },
}));

const baseURL =
  process.env.NODE_ENV !== "production"
    ? "http://localhost:5000/"
    : window.location.origin + "/";

const UploadDocument = ({ handleChange }) => {
  const navigate = useNavigate();
  const [form, setForm] = useState({});
  const [loading, setLoading] = useState(true);
  const [loadingButton, setLoadingButton] = useState(false);
  const [loadingUpload, setLoadingUpload] = useState(false);
  const [preview, setPreview] = useState("");
  const [openPreivew, setOpenPreview] = useState(false);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [validate, setValidate] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);
  const [tempBio, setTempBio] = useState({});
  const [inv, setInv] = useState({});
  const [validateTxt] = useState("* required");
  const validates = JSON.parse(localStorage.getItem("validates"));
  const val = parseInt(localStorage.getItem("value"));

  useEffect(() => {
    fetchDoc();
  }, []);

  const checkRequirement = () => {
    if (
      !form.identity_card ||
      !form.graduation_certificate ||
      !form.grade_transcript ||
      !form.resume
    ) {
      setValidate(true);
      return true;
    } else {
      setValidate(false);
      return false;
    }
  };

  const fetchDoc = async () => {
    setLoading(true);
    const resDoc = await getPersonalDocument();
    if (resDoc.data[0]) {
      const resGT = await loadImage(resDoc.data[0].grade_transcript);
      const resGS = await loadImage(resDoc.data[0].graduation_certificate);
      const resResume = await loadImage(resDoc.data[0].resume);
      resDoc.data[0].new_grade_transcript = resGT;
      resDoc.data[0].new_graduation_certificate = resGS;
      resDoc.data[0].new_resume = resResume;
    }
    setForm(resDoc.data[0] || {});
    setLoading(false);
  };

  const handleUpload = async (files, key) => {
    if (files.length) {
      setLoadingUpload(true);
      const resUpload = await uploadFileJp(files[0]);
      if (typeof resUpload === "string") {
        setLoadingUpload(false);
        setSnackbarOpen("error", resUpload);
        return;
      }
      const copyForm = { ...form };
      copyForm[key] = resUpload.data?.path;
      setForm(copyForm);
      if (form.bio_document_id) {
        await updatePersonalDocument(copyForm);
      } else {
        await createPersonalDocument(copyForm);
      }
      setLoadingUpload(false);
      setSnackbarOpen("success", "Data tersimpan");
      fetchDoc();
    }
  };

  const handleUploadKtp = async (files, key) => {
    if (files.length) {
      setLoadingUpload(true);
      const resUpload = await uploadKtpJp(files[0]);
      console.log(resUpload.data);
      // const copyForm = { ...form };
      // copyForm[key] = resUpload.data?.path;
      // setForm(copyForm);
      // if (form.bio_document_id) {
      //   await updatePersonalDocument(copyForm);
      // } else {
      //   await createPersonalDocument(copyForm);
      // }
      alert(resUpload.data.nik);
      setLoadingUpload(false);
      setSnackbarOpen("success", "Data tersimpan");
      fetchDoc();
    }
  };

  const handleOpenPreview = (d) => {
    setPreview(d);
    setOpenPreview(!openPreivew);
  };

  const handleDownloadFlk = async () => {
    if (loadingButton) {
      return;
    } else {
      const token = await localforage.getItem("token");
      setLoadingButton(true);
      const resDownload = await downloadFLK({
        candidate_id: form?.identity_number,
        token,
      });
      setLoadingButton(false);
      // Usage
      const base64String = resDownload.data; // Replace with your Base64 data
      const fileName = Date.now() + ".pdf"; // Replace with your desired file name
      downloadBase64AsFile(base64String, fileName);
    }
  };

  const handleSubmit = async () => {
    if (localStorage.getItem("invited")) {
      setLoadingButton("submit-invited");
      // ini nanti open modal yg vacancy
      const invitedStr = localStorage.getItem("invited");
      const invited = JSON.parse(invitedStr);
      setInv(invited);
      const resCheck = await candidateCheckApply({
        job_posting_id: invited.job_posting_id,
      });
      if (resCheck.code === 200) {
        setTempBio(resCheck.data);
        setOpenAlert(true);
      } else {
        setSnackbarOpen("error", resCheck.data.message);
      }
    } else {
      navigate(`/${version}/user/vacancy`)
    }
  };

  if (loading) return <CircularProgress />;

  return (
    <Container>
      <Typography sx={{ fontSize: "24px", mb: 3 }}>
        <b>Upload Dokumen</b>
      </Typography>
      <Stack>
        <Typography sx={{ fontWeight: 600, fontSize: "16px" }}>
          {`[Gambar/Image] Kartu Identitas (KTP)`}
        </Typography>
        <Stack direction="row" spacing={2}>
          {form.identity_card ? (
            <Button
              variant="contained"
              endIcon={<DownloadDoneRoundedIcon />}
              onClick={() => handleOpenPreview("identity_card")}
            >
              Preview
            </Button>
          ) : null}
          <Button variant="outlined" component="label" disabled={loadingUpload}>
            Upload Kartu Identitas
            <input
              hidden
              accept="image/*"
              type="file"
              onChange={(e) => handleUpload(e.target.files, "identity_card")}
            />
          </Button>
        </Stack>
      </Stack>
      {validate && !form.identity_card && (
        <Typography
          variant="caption"
          sx={{ color: "#ff0000", textAlign: "center" }}
        >
          {validateTxt}
        </Typography>
      )}

      <Stack sx={{ mt: "2rem" }}>
        <Typography sx={{ fontWeight: 600, fontSize: "16px" }}>
          {`[PDF] Ijazah/Surat Keterangan Lulus `}
          <span style={{ fontSize: "13px", fontWeight: "normal" }}>
            * jika lebih dari satu halaman, dijadikan dalam satu pdf terlebih
            dahulu
          </span>
        </Typography>
        <Stack direction="row" spacing={2}>
          {form.graduation_certificate ? (
            <Button
              variant="contained"
              endIcon={<DownloadDoneRoundedIcon />}
              onClick={() => handleOpenPreview("graduation_certificate")}
            >
              Preview
            </Button>
          ) : null}
          <Button variant="outlined" component="label" disabled={loadingUpload}>
            Upload Ijazah/Surat Keterangan Lulus
            <input
              hidden
              accept="application/pdf"
              type="file"
              onChange={(e) =>
                handleUpload(e.target.files, "graduation_certificate")
              }
            />
          </Button>
        </Stack>
      </Stack>
      {validate && !form.graduation_certificate && (
        <Typography
          variant="caption"
          sx={{ color: "#ff0000", textAlign: "center" }}
        >
          {validateTxt}
        </Typography>
      )}
      <Stack sx={{ mt: "2rem" }}>
        <Typography sx={{ fontWeight: 600, fontSize: "16px" }}>
          {`[PDF] Transkrip Nilai `}
          <span style={{ fontSize: "13px", fontWeight: "normal" }}>
            * jika lebih dari satu halaman, dijadikan dalam satu pdf terlebih
            dahulu
          </span>
        </Typography>
        <Stack direction="row" spacing={2}>
          {form.grade_transcript ? (
            <Button
              variant="contained"
              endIcon={<DownloadDoneRoundedIcon />}
              onClick={() => handleOpenPreview("grade_transcript")}
            >
              Preview
            </Button>
          ) : null}
          <Button variant="outlined" component="label" disabled={loadingUpload}>
            Upload Transkrip Nilai
            <input
              hidden
              accept="application/pdf"
              type="file"
              onChange={(e) => handleUpload(e.target.files, "grade_transcript")}
            />
          </Button>
        </Stack>
      </Stack>
      {validate && !form.grade_transcript && (
        <Typography
          variant="caption"
          sx={{ color: "#ff0000", textAlign: "center" }}
        >
          {validateTxt}
        </Typography>
      )}

      <Stack sx={{ mt: "2rem" }}>
        <Typography sx={{ fontWeight: 600, fontSize: "16px" }}>
          {`[PDF] Resume / CV`}
        </Typography>
        <Stack direction="row" spacing={2}>
          {form.resume ? (
            <Button
              variant="contained"
              endIcon={<DownloadDoneRoundedIcon />}
              onClick={() => handleOpenPreview("resume")}
            >
              Preview
            </Button>
          ) : null}
          <Button variant="outlined" component="label" disabled={loadingUpload}>
            Upload Resume/CV
            <input
              hidden
              accept="application/pdf"
              type="file"
              onChange={(e) => handleUpload(e.target.files, "resume")}
            />
          </Button>
        </Stack>
      </Stack>

      <Stack sx={{ mt: "1rem" }}>
        <Button
          variant="contained"
          startIcon={
            loadingButton ? (
              <CircularProgress color="white" size={24} />
            ) : (
              <HistoryEduRoundedIcon />
            )
          }
          onClick={handleDownloadFlk}
        >
          Download FLK
        </Button>
      </Stack>

      {validate && !form.resume && (
        <Typography
          variant="caption"
          sx={{ color: "#ff0000", textAlign: "center" }}
        >
          {validateTxt}
        </Typography>
      )}

      <Alert
        severity="info"
        sx={{ mt: "2rem" }}
        action={
          <Button
            variant="contained"
            onClick={() => {
              if (checkRequirement()) return;
              setOpenConfirm(true);
            }}
          >
            Submit
          </Button>
        }
      >
        Setelah mengisi, klik "Submit" untuk melengkapkan proses. Jangan lupa
        kunjungi halaman lowongan untuk lamaran lebih lanjut. Terima kasih!
      </Alert>

      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        sx={{ width: "100%", my: "1.5rem" }}
      >
        <Button
          variant="outlined"
          sx={{
            border: "none",
            opacity: validates[val - 1] ? 1 : 0,
          }}
          onClick={(e) => handleChange(e, val - 1)}
          disabled={!validates[val - 1]}
          startIcon={<KeyboardArrowLeft />}
        >
          Riwayat Kesehatan
        </Button>
      </Stack>

      <CustomDialog
        open={openPreivew}
        fullWidth
        maxWidth="md"
        onClose={() => {
          setOpenPreview(false);
          setPreview("");
        }}
      >
        <DialogTitle sx={{ mb: "1rem" }}>
          <IconButton
            aria-label="close"
            onClick={() => {
              setOpenPreview(false);
              setPreview("");
            }}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        {preview === "identity_card" ? (
          <LoadableImage
            src={form.identity_card}
            alt="ktp"
            style={{ maxWidth: "500px", margin: "auto" }}
          />
        ) : (
          <object
            data={form[`new_${preview}`]}
            type="application/pdf"
            width="100%"
            height="700px"
          >
            <p>
              Unable to display PDF file.{" "}
              <a href={baseURL + form[preview]}>Download</a> instead.
            </p>
          </object>
        )}
      </CustomDialog>

      <CustomDialog
        open={openConfirm}
        fullWidth
        maxWidth="sm"
        onClose={() => setOpenConfirm(false)}
      >
        <DialogTitle>Konfirmasi</DialogTitle>
        <DialogContent sx={{ lineHeight: "22px" }}>
          “Saya menyatakan bahwa apa yang saya tulis di formulir ini adalah
          sesuai dengan fakta yang sebenarnya. Saya menyetujui bahwa pihak PT
          Pamapersada Nusantara mempunyai hak untuk menolak aplikasi saya atau
          memutuskan hubungan kerja apabila data di atas tidak sesuai dengan
          fakta yang sebenarnya.”
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenConfirm(false)}>Cancel</Button>
          <Button
            variant="contained"
            onClick={handleSubmit}
            disabled={loadingButton === "submit-invited"}
          >
            Submit
          </Button>
        </DialogActions>
      </CustomDialog>

      {openAlert && (
        <ModalConfirmApplyJob
          open={openAlert}
          defaultBio={tempBio}
          jobPostingId={inv?.job_posting_id || ""}
          positionGroupName={inv?.position_group_name || ""}
          onClose={() => setOpenAlert(false)}
          setLoadingButton={setLoadingButton}
          invited_generator_id={inv?.invited_generator_id || ""}
        />
      )}
    </Container>
  );
};

export default UploadDocument;
