import React, { useState, useEffect } from "react";
import {
  Typography,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  useTheme,
  IconButton,
  styled,
  CircularProgress,
  DialogActions,
  Stack,
  Grid,
  Tooltip,
  Paper,
} from "@mui/material";
import {
  getJpExtensionCnForm,
  updateJpExtensionCn,
  updateJpExtensionCnForm,
  uploadFileJp,
  setSnackbarOpen,
} from "src/redux/actions";
import { Delete, Download, Remove, Upload } from "@mui/icons-material";
import { DropzoneArea } from "material-ui-dropzone";

const CustomDialog = styled(Dialog)(() => ({
  "& .MuiDialog-paper": {
    backgroundColor: "rgb(255,255,255)",
    borderRadius: "1rem",
    border: "1px solid lightgrey",
    // maxWidth: "350px",
  },
}));

const ModalUploadTreatment = ({ open, onClose, dataDetail }) => {
  useTheme();
  const [loading, setLoading] = useState("content");
  const [loadingButton, setLoadingButton] = useState(false);
  const [data, setData] = useState([]);
  const [openAlert, setOpenAlert] = useState(false);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    setLoading("content");
    const res = await getJpExtensionCnForm({
      candidate_id: dataDetail.candidate_id,
    });
    setData(res.data);
    setLoading("");
  };

  const handleUpload = async (files, key, form) => {
    if (files.length) {
      const resUpload = await uploadFileJp(files[0]);
      if (typeof resUpload === "string") {
        setSnackbarOpen("error", resUpload);
        return;
      }
      form.file_path = resUpload.data.path;
      await updateJpExtensionCnForm(form);

      fetchData();
    }
  };

  const handleDownload = (link) => {
    const baseURL =
      process.env.NODE_ENV !== "production"
        ? "http://localhost:5000/"
        : window.location.origin + "/";
    var url = baseURL + link;
    var link = document.createElement("a");
    link.href = url;
    link.click();
  };

  const handleSend = async () => {
    console.log(dataDetail.extension[0].job_posting_extension_id);
    const sendParams = {
      candidate_id: dataDetail.candidate_id,
      job_posting_extension_id:
        dataDetail.extension[0].job_posting_extension_id,
      update_type: "upload-treatment",
    };
    setLoadingButton(true);
    const res = await updateJpExtensionCn(sendParams);
    if (res.code !== 200) {
      return;
    }
    setSnackbarOpen("success", "Anda berhasil mengajukan waktu MCU");
    setLoadingButton(false);
    setOpenAlert(false);
    setLoading("");
    onClose();
  };

  if (loading === "content") return <CircularProgress />;

  return (
    <>
      <CustomDialog open={open} maxWidth="sm" fullWidth>
        <DialogTitle>
          <b>Silahkan upload dokumen treatment di bawah</b>
        </DialogTitle>
        <DialogContent>
          {loading === "content" ? (
            <CircularProgress />
          ) : (
            <Stack>
              {data.map((d) => (
                <Paper elevation={0} sx={{ p: "0.5rem 1rem" }}>
                  <Grid
                    container
                    spacing={1}
                    key={d.job_posting_extension_form_id}
                  >
                    <Grid
                      item
                      xs={d.request_notes ? 7 : 12}
                      sx={{ m: "auto 0" }}
                    >
                      <Typography
                        style={{ fontWeight: "bold", fontSize: "16px" }}
                      >
                        {d.request_file_name}
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={d.request_notes ? 4 : 0}
                      sx={{ m: "auto 0" }}
                    >
                      <Typography>{d.request_notes}</Typography>
                    </Grid>
                    {d.file_path && (
                      <Grid item xs={1}>
                        <Tooltip title="Download Dokumen">
                          <IconButton
                            onClick={() => handleDownload(d.file_path)}
                          >
                            <Download />
                          </IconButton>
                        </Tooltip>
                      </Grid>
                    )}
                    <Grid item xs={12}>
                      <DropzoneArea
                        filesLimit={1}
                        onChange={(e) => handleUpload(e, "file_path", d)}
                        // acceptedFiles={["image/*"]}
                        showPreviews={true}
                      />
                    </Grid>
                  </Grid>
                </Paper>
              ))}
            </Stack>
          )}
        </DialogContent>

        <DialogActions>
          <Button onClick={onClose}>Batal</Button>
          {loading === "buttonApply" ? (
            <CircularProgress />
          ) : (
            <Button
              variant="contained"
              onClick={() => setOpenAlert(true)}
              // disabled={!form.city}
            >
              Simpan & Kirim
            </Button>
          )}
        </DialogActions>
      </CustomDialog>

      <CustomDialog open={openAlert} maxWidth="xs" fullWidth>
        <DialogTitle>
          <b>Konfirmasi</b>
        </DialogTitle>
        <DialogContent>
          <Typography>
            Dokumen Anda akan diproses untuk review treatment
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenAlert(false)}>Kembali</Button>
          <Button
            variant="contained"
            onClick={handleSend}
            disabled={loadingButton}
            startIcon={loadingButton ? <CircularProgress /> : null}
          >
            Ya, Lanjutkan
          </Button>
        </DialogActions>
      </CustomDialog>
    </>
  );
};

export default ModalUploadTreatment;
