import React from "react";
import ReactHtmlParser from "react-html-parser";
import { Typography, Button } from "@mui/material";
import shortid from "shortid";
import noImage from "src/assets/img/no-image.jpg";
import noVideo from "src/assets/img/noVideo.jpg";
import SimpleScrollItem from "src/components/Layout/SimpleScrollItem";
import NavigationBar from "src/components/Layout/NavigationBar";
import BasicFooter from "src/components/Layout/BasicFooter";
import MyCareer from "src/components/Tables/MyCareer";
import Vacancy from "src/components/Tables/Vacancy";
import Profile from "src/pages/user/Profile/index";
import RegisterV3 from "src/components/RegisterV3";
import RegisterGeneral from "src/components/RegisterGeneral";
import DetailVacancy from "src/pages/user/ProductDetail/DetailVacancy";
import FAQ from "src/pages/user/FAQ/FAQCom";
import ArticleList from "src/pages/user/Article/ArticleList";
import ContainerCardProduct from "src/pages/user/Products/ContainerCardProduct";
import LoadableImage from "src/components/LoadableImage";
import "../../../App.css";

export const ITEM = "item";
export const ROW = "row";
export const COLUMN = "column";
export const ELEMENT = "element";

export const Row = {
  type: "row",
  sectProps: {},
  props: {},
};

export const Column = {
  type: "column",
  colWidth: 12,
  props: {},
};

const SimpleScrollSection = {
  sectCn: "simpleScroll",
  sectProps: {},
  props: { maxWidth: "80%" },
  visibility: true,
  children: [
    {
      type: "column",
      id: shortid.generate(),
      colWidth: 4,
      colCn: "simpleScroll",
      props: { height: "23rem" },
      children: [
        {
          type: "element",
          id: shortid.generate(),
          variant: "element0",
          html: `<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>`,
          htmlEn: "",
        },
      ],
    },
    {
      type: "column",
      id: shortid.generate(),
      colWidth: 8,
      props: {},
      colCn: "simpleScrollItem custom-scrollbar x",
      children: [
        {
          type: "element",
          id: shortid.generate(),
          variant: "element10",
          children: [
            {
              type: "element",
              id: shortid.generate(),
              variant: "element3",
              // cardProps: {
              //   width: "13rem",
              //   height: "20rem",
              // },
              src: "",
              url: "",
              elCn: "simple-scroll",
              text: "",
              textEn: "",
            },
            {
              type: "element",
              id: shortid.generate(),
              variant: "element3",
              // cardProps: {
              //   width: "13rem",
              //   height: "20rem",
              // },
              src: "",
              url: "",
              elCn: "simple-scroll",
              text: "",
              textEn: "",
            },
            {
              type: "element",
              id: shortid.generate(),
              variant: "element3",
              // cardProps: {
              //   width: "13rem",
              //   height: "20rem",
              // },
              src: "",
              url: "",
              elCn: "simple-scroll",
              text: "",
              textEn: "",
            },
          ],
        },
      ],
    },
  ],
};

const ImageStackSection = {
  sectProps: {},
  props: { backgroundColor: "#F5F5F5" },
  visibility: true,
  children: [
    {
      type: "column",
      id: shortid.generate(),
      colWidth: 6,
      colCn: "img-stack",
      props: { aspectRatio: "4/5" },
      children: [
        {
          type: "element",
          id: shortid.generate(),
          variant: "element3",
          src: "",
          elCn: "img-stack",
          props: { aspectRatio: "4/5" },
        },
      ],
    },
    {
      type: "column",
      id: shortid.generate(),
      colWidth: 6,
      props: { aspectRatio: "4/5" },
      children: [
        {
          type: "element",
          id: shortid.generate(),
          variant: "element0",
          html: `<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>`,
          htmlEn: "",
        },
      ],
    },
  ],
};

export const initialElement = [
  {
    type: ITEM,
    id: shortid.generate(),
    variant: "element0",
    variantText: "Text",
    content: "Some Text",
    html: "<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>",
    htmlEn: "",
  },
  {
    type: ITEM,
    id: shortid.generate(),
    variant: "element1",
    variantText: "Youtube Video",
    content: "Some Youtube Video",
    src: "",
    props: {
      width: "100%",
      objectFit: "cover",
      aspectRatio: "16/9",
    },
  },
  {
    type: ITEM,
    id: shortid.generate(),
    variant: "element2",
    variantText: "Video",
    content: "Some Video",
    poster: "",
    src: "",
    props: {
      width: "100%",
      objectFit: "cover",
      aspectRatio: "16/9",
    },
  },
  {
    type: ITEM,
    id: shortid.generate(),
    variant: "element3",
    variantText: "Image",
    content: "Some Image",
    src: "",
    props: {
      width: "100%",
      objectFit: "cover",
      aspectRatio: "16/9",
    },
  },
  {
    type: ITEM,
    id: shortid.generate(),
    variant: "element4",
    variantText: "Button",
    content: "Some Button",
    name: "Some Button",
    nameEn: "",
    url: "",
    variantBtn: "contained",
    props: {},
  },
  // {
  //   type: ITEM,
  //   id: shortid.generate(),
  //   variant: "section0",
  //   variantText: "Section Kosong",
  //   content: "Section Kosong",
  // },
  {
    type: ITEM,
    id: shortid.generate(),
    variant: "section0",
    variantText: "Simple Scroll Section",
    content: "Simple Scroll Section",
    ...SimpleScrollSection,
  },

  {
    type: ITEM,
    id: shortid.generate(),
    variant: "section00",
    variantText: "Image Stack Section",
    content: "Image Stack Section",
    ...ImageStackSection,
  },
];

export const listElements = (data, lang, src, poster) => {
  // seri satuan untuk element basic
  // seri puluhan untuk element section template (1x) landing page, (2x) product page
  switch (data.variant) {
    case "element0": {
      return ReactHtmlParser(lang === "en" ? data.htmlEn : data.html);
    }
    case "element1": // youtube video
      return (
        <>
          {data.src ? (
            <iframe
              src={data.src}
              style={data.props}
              title={data.id}
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowFullScreen
            ></iframe>
          ) : (
            <LoadableImage
              alt={"no video"}
              src={noVideo}
              style={{ width: "100%", objectFit: "cover", aspectRatio: "16/9" }}
            />
          )}
        </>
      );
    case "element2": // common video
      return (
        <video
          controls
          poster={data.poster ? poster : noVideo}
          style={data.props}
        >
          <source src={src} type="video/mp4" />
          <p>Your browser doesn't support HTML video.</p>
        </video>
      );
    case "element3":
      return (
        <LoadableImage
          className={data.elCn && `el ${data.elCn}`}
          alt={data.id}
          src={data.src ? data.src : noImage}
          style={data.props}
          // onClick={() => (data.url ? window.location.replace(data.url) : null)}
        />
      );
    case "element4":
      return (
        <Button
          variant={data.variantBtn}
          style={{ ...data.props }}
          // onClick={() => window.location.replace(data.url)}
        >
          {lang === "en" ? data.nameEn : data.name}
        </Button>
      );
    case "element5":
      return (
        <Typography {...data.props}>
          {lang === "en" ? data.textEn : data.text}
        </Typography>
      );
    case "element10":
      return <SimpleScrollItem data={data} />;
    case "element11":
      return <NavigationBar data={data} />;
    case "element12":
      return <BasicFooter data={data} />;
    // case "element20":
    //   return <ProductAsideRecruitment data={data} />;
    case "element21":
      return <Vacancy data={data} />; //table
    case "element22":
      return <ContainerCardProduct data={data} />;
    // case "element30":
    //   return <DashboardAsideRecruitment data={data} />;
    case "element31":
      return <MyCareer data={data} />;
    // case "element40":
    //   return <ProfileAsideRecruitment data={data} />;
    case "element41":
      return <Profile data={data} />;
    case "element51":
      return <DetailVacancy data={data} />;
    case "element61":
      return <FAQ data={data} />;
    case "element71":
      return <ArticleList data={data} />;
    case "element90":
      return <RegisterGeneral data={data} />;
    case "element91":
      return <RegisterV3 data={data} />;
    default:
      return <></>;
  }
};
