export function formatRupiah(angka) {
  var reverse = angka?.toString()?.split("")?.reverse()?.join(""),
    ribuan = reverse?.match(/\d{1,3}/g);
  ribuan = ribuan?.join(".")?.split("")?.reverse()?.join("");
  return "Rp " + ribuan;
}

export const loadImage = async (url, type) => {
  if (!url || url.indexOf("assets/files") === -1) return url;
  // https://career.pamapersada.com/
  const domainAssets =
    window.location.hostname === "localhost"
      ? `https://pesantrenkoding.com/${url}`
      : `${window.location.origin}/${url}`;

  // const newUrl = url && url.indexOf("assets/files") !== -1 ? domainAssets : url;
  console.log({ domainAssets });
  const newUrl = domainAssets;

  try {
    const response = await fetch(newUrl, {
      headers: { "file-headers": "F1L3-H34D3RS" },
    });
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    const blob = await response.blob();
    let objectURL;
    if (type === "video") {
      // const buf = await blob.arrayBuffer();
      // objectURL = URL.createObjectURL(new Blob([buf]));
      objectURL = URL.createObjectURL(blob);
    } else {
      objectURL = URL.createObjectURL(blob);
    }
    return objectURL;
  } catch (error) {
    console.error("Error loading image:", error);
  }
};
